import { forwardRef, Inject, Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router"
import { Observable } from "rxjs"
import { AdminUiAuthService } from "../service-api/admi-ui-auth.service"

@Injectable()
export class WebhookGuard implements CanActivate {
  constructor(
    @Inject(forwardRef(() => AdminUiAuthService)) private adminUiAuthService: AdminUiAuthService,
    private router: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.adminUiAuthService.isWebhookUser() && next.routeConfig?.path === 'webhook') {
      this.router.navigate(['/home']); // Redirect to home if not authorized
      return false;
    }
    return true;
  }
}
